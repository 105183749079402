.center-container {
    height: 80vh;
    width: 400px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.center-activation {
    position: relative;
    text-align: center;
    min-width: 400px;
    top: 45%;
    -ms-transform: translateY(-45%);
    transform: translateY(-45%);
}

#activation-messsage {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.alert-success{
    padding-top: 5px;
    padding-bottom: 10px;
    border-radius: 7px;
}


.success-icon,
#spinningbar {
    margin-top: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .center-activation {
        min-width: 40vw;
    }
    #activation-message {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .center-container {
        height: 80vh;
        width: 80vw;
    }
}
