.generic-form button {
	font-family: 'DINPro';
	padding: 5px 5px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	display: block;
	transition: all 0.2s ease;
	width: 100%;
	align-items: center;
	border-radius: 7px;
	border: 1px solid black;
	background-color: white;
	color: black;
}

.generic-form button:hover {
	background-color: black;
	color: white;
	border: 1px solid black;
}

#generic-form {
	font-family: DINPro;
	align-self: center;
	box-shadow: 0 4px 8px #0003;
	border-radius: 7px;
	padding: 20px;
}

.selected-button {
	background-color: black !important;
	color: white !important;
	border: 1px solid black;
}

.generic-form {
	margin: 0;
	margin: auto;
	margin-top: 0.3vw;
	margin-bottom: 2.5vw;
	width: 30vw;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
}

.generic-form-password {
	margin-top: 18vh;
	width: 45vh;
}

.form-ride-details {
	margin-top: 4vh;
}

.form-ride-invalid {
	margin-top: 12vh;
	width: 30vw;
}


@media all and (max-width: 800px) {
	.generic-form {
		margin: 0;
		margin: auto;
		margin-top: 10vw;
		margin-bottom: 100px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		transition: 0.3s;
	}

	#closedMessage {
		margin-bottom: -90px;
		top: 28vh;
		font-weight: 900;
	}

	.generic-form-password {
		margin-top: 15vh;
		width: 80vw;
		margin-bottom: 20vh;
	}


	.form-ride-details {
		margin-top: 2vh;
		width: 80vw;
		margin-bottom: 20vh;
	}

	.form-ride-invalid {
		margin-top: 6vh;
		width: 80vw;
		margin-bottom: 20vh;
	}
}

.tbc-logo {
	width: 100%;
	margin: auto;
	align-items: center;
	padding-bottom: 40px;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	align-self: center;
	border-radius: 7px;
}

form {
	font-family: 'DINPro';
	width: 28vw;
	min-width: 500px;
	min-height: 300px;
	align-self: center;
	box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
		0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
	border-radius: 7px;
	padding: 20px;
}

img {
	vertical-align: middle;
	border-style: none;
}

.submit-btn {
	font-family: 'DINPro';
	background-color: var(--primary-color);
	color: var(--secondary-color);
	border-radius: 7px;
	border: 1px solid black;
	height: calc(1.5em + 0.75rem + 2px);
}

.submit-btn:hover {
	background-color: white;
	border: 1px solid black;
	color: black;
}

.operations-link {
	color: #0492c2;
}

.operations-link:hover {
	color: #383d41;
	text-decoration: none;
}

#standbyCheckbox {
	width: 16px;
	height: 16px;
}