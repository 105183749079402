:root {
    --bottom-buffer: 0px;
    --header-buffer: 20vw;
    --row-height: 35px;
    --padding-height: 45px;
    --primary-color: #000000;
    --secondary-color: #ffffff;
    --active-color: #dddddd;
}

.topnav {
    max-height: var(--row-height);
    top: 0;
}

.footer-container {
    position: fixed;
    left: 0;
    bottom: 0;
    bottom: var(--bottom-buffer);
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    margin: 0px;
    padding: 0px;
}

.footer-row {
    max-height: var(--row-height);
}

footer {
    background-color: var(--primary-color);
    padding-bottom: var(-bottom-buffer);
    z-index: 2147483647;
}

/* Add a black background color to the top navigation */
.topnav,
.footer-row {
    display: flex;
    flex-direction: row;
    background-color: var(--primary-color);
    overflow: hidden;
    justify-content: space-around;
    margin: 0;
    padding: 0 35% 0 35%;
}

/* Style the links inside the navigation bar */
.topnav a,
.footer-row a {
    float: left;
    color: var(--secondary-color);
    text-align: center;
    padding: 10px 12px;
    text-decoration: none;
    font-size: 16px;
}

/* Change the color of links on hover */
.topnav a:hover,
.footer-row a:hover {
    color: var(--active-color);
    background-color: var(--primary-color);
    text-decoration: none;
}

/* Add a color to the active/current link */
.topnav a.active {
    background-color: var(--active-color);
    color: var(--primary-color);
}

.copyright-tag {
    font-size: 12px;
    height: 20px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    color: #a9a9a9;
    text-align: center;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: white;
}

.hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0px;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hover-underline-animation-top {
    display: inline-block;
    position: relative;
    color: white;
}

.hover-underline-animation-top:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 1px;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation-top:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

@media all and (max-width: 800px) {
    .copyright-tag {
        font-size: 12px;
        height: 28px !important;
        color: #a9a9a9;
        text-align: center;
    }
}

.contact-phone {
    color: #0492c2;
}

.contact-phone:hover {
    color: white;
    text-decoration: none;
}
