@font-face {
  font-family: 'DINPro';
  src: url('./assets/DINPro.otf');
  src: url('./assets/DINPro.ttf');
}

body {
  font-family: 'DINPro' !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}